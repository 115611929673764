
import { $gettext } from 'vue-gettext';
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import { customIcons, slashIcon } from '../icon/BpIcon';
import { defaultComponent, Component, useVersionStore, Product } from '@/stores/version';
import { defaultTranslated, getTranslated } from '@/translation';
import { isEqual, OptionalKeys } from '@/utils/object';
import { PopoverElement } from '../popover/BpPopoverMenu';
import { useFormErrors } from '@/utils/form-errors';
import { useRouter } from 'vue-router';
import BpToast from '../toast/BpToasts';
import clone from '@sahnee/clone';

export default defineComponent({
  name: 'bp-version-component-editor',
  props: {
    modelValue: Object as PropType<Component>,
    loading: Boolean,
  },
  setup(props) {
    ///-------------------------------------------------------------------
    /// ROUTER
    ///-------------------------------------------------------------------

    const router = useRouter();
    const isEdit = computed(() => router.currentRoute.value.name?.toString().endsWith('edit') || false);

    ///-------------------------------------------------------------------
    /// COMPONENT
    ///-------------------------------------------------------------------

    const initialComponent: OptionalKeys<Component, '_id' | '_rev'> = {
      ...defaultComponent,
      parent_id: (router.currentRoute.value.query.parent as string) || '',
    };

    const component = ref<OptionalKeys<Component, '_id' | '_rev'>>({ ...clone(initialComponent), ...clone(props.modelValue) });
    watch(() => props.modelValue, () => {
      if (!props.modelValue || isEqual(component.value, props.modelValue)) {
        return;
      }
      reset();
    }, { immediate: true, deep: true });

    ///-------------------------------------------------------------------
    /// PARENT
    ///-------------------------------------------------------------------

    const parents = computed(() => {
      return store.getParents({ excludeIds: component.value._id })
        .map(parent => {
          return ({ ...parent, icon: store.getIcon(parent) })
        }) as PopoverElement[];
    });

    const transformParent = (parent: Product | Component) => {
      const path = store.getPathById(parent._id);
      if (path.length > 0) {
        return `${store.getName(parent)} <em style="opacity: 0.5;">(${path.map(parent => store.getName(parent)).join(' > ')})</em>`;
      } else if (parent._id === 'root') {
        return `<em style="opacity: 0.5;">(${store.getName(parent)})</em>`
      }
      return store.getName(parent) || '';
    };

    ///-------------------------------------------------------------------
    /// RESET
    ///-------------------------------------------------------------------

    function reset() {
      if (props.modelValue && isEqual(component.value, props.modelValue)) { return }

      // Reset meta key errors from meta keys added before resetting the form
      const metaKeys = Object.keys({ ...clone(initialComponent), ...props.modelValue ? clone(props.modelValue) : {} }.meta);
      const currentMetaKeys = Object.keys(component.value.meta);
      for (const key of currentMetaKeys) {
        if (!metaKeys.includes(key)) {
          errors.value.delete(`meta.${key}`);
        }
      }

      // Reset the component value
      component.value = { ...clone(initialComponent), ...clone(props.modelValue) };
    }

    ///-------------------------------------------------------------------
    /// ERROR
    ///-------------------------------------------------------------------

    const { errors, setError } = useFormErrors<Component>()

    ///-------------------------------------------------------------------
    /// SAVE
    ///-------------------------------------------------------------------

    const store = useVersionStore();

    async function save() {
      let response;
      component.value.doc_type = 'version_component';
      if (component.value._id) {
        response = await store.update(component.value);
      } else {
        response = await store.create(component.value);
      }
      if (response?.success) {
        BpToast.show({
          color: 'green',
          title: component.value._id
            ? $gettext('Component successfully updated')
            : $gettext('Component successfully created'),
          content: component.value._id
            ? $gettext('The component was successfully updated.')
            : $gettext('The component was successfully created.'),
          icon: 'circle-check',
        });
        router.replace({ name: 'admin.version.overview' });
      } else {
        BpToast.show({
          color: 'red',
          title: component.value._id
            ? $gettext('Failed to update component')
            : $gettext('Failed to create component'),
          content: component.value._id
            ? $gettext('The component could not be updated: %{error}', { error: response?.error })
            : $gettext('The component could not be created: %{error}', { error: response?.error }),
          icon: 'circle-check',
        });
      }
    }

    ///-------------------------------------------------------------------
    /// META
    ///-------------------------------------------------------------------

    function updateMetaKeys(metaKeys: string[]) {
      const currentKeys = Object.keys(component.value.meta);
      for (const key of metaKeys) {
        if (!currentKeys.includes(key)) {
          component.value.meta[key] = defaultTranslated();
        }
      }
      for (const key of currentKeys) {
        if (!metaKeys.includes(key)) {
          delete component.value.meta[key];
          errors.value.delete(key);
        }
      }
    }

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      component,
      customIcons,
      errors,
      getTranslated,
      isEdit,
      parents,
      reset,
      save,
      setError,
      slashIcon,
      store,
      transformParent,
      updateMetaKeys,
    }
  }
})
